<template>
    <client-page>
        <sub-visual tit="LED 광고 솔루션" bg="/images/sub/visual/sv--product.jpg">
            <p class="page-text line-height-15 mt-8 mt-lg-20 color-grey-c">
                누구나 쉽고 안전하게 출력물 교체가 가능한 EZFLIP™ 안전개폐 프레임. 거친 환경에서도 사용 가능한 획기적인 QFABRIC™ 패브릭 프레임. <br class="d-none d-lg-block">
                도트 현상이 없는 특별 LED 설계. 폭넓은 특수 환경에서 사용 가능한 다양한 제품들을 확인해 보세요.
            </p>
        </sub-visual>

        <section class="section">
            <v-container>
                <router-link to="/" class="text-btn d-flex align-center"><i class="icon icon-left-arrow mr-8"></i>Back to main</router-link>
                <div class="mt-12 mt-lg-20">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="program, idx in programs" :key="idx">
                            <div @click="show(program)" style="cursor: pointer" class="h-100">
                                <card-primary>
                                    <div class="thumb">
                                        <div class="thumb__inner" :style="`background-image:url('${program.thumb?.url}');`"></div>
                                    </div>
                                    <div class="w-100 pa-20 pa-md-30">
                                        <p class="page-text--sm color-grey-7 line-height-1 mb-2 mb-lg-6">
                                            {{program.subTitle}}
                                        </p>
                                        <h5 class="tit-txt font-weight-bold line-height-13 word-keep-all">
                                            {{program.subject}}
                                        </h5>
                                        <v-divider class="border-color-dark my-12 my-lg-20" />
                                        <p class="page-text--sm line-height-13">
                                            {{program.content}}
                                        </p>
                                    </div>
                                </card-primary>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" @input="search"/>
                </div>

            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";
import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
    },
    data() {
        return {
            programs: [],
            code: this.$route.query.code,

            page: 1,
            pageCount: 0,
			limit: 6,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
			this.search();
		},
        async search() {
            let { summary, programs } = await api.v1.auction.programs.gets({ 
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                    sort: "boardNum",
                },
                params: { code: "led" , language: "ko" }});

            this.programs = programs;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);

        },
        show(program) {
            this.$router.push(`/series/product?category=${program.subject}`);
        }
    },
    watch: {
        "$route.query.category"() {
            this.init();
        }
    }
};
</script>

<style scoped>
</style>
