var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "v-card--default h-100",
    attrs: {
      "rounded": "",
      "elevation": "0"
    }
  }, [_c('div', {
    class: 'h-100 border border-color-' + _vm.borderColor
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }