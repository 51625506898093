<template>
    <v-card rounded elevation="0" class="v-card--default h-100">
        <div :class="'h-100 border border-color-'+borderColor">
            <slot/>
        </div>
    </v-card>
</template>

<script>
export default {
    props : {
        borderColor : {type :String, default : ""},
    },
}
</script>

<style scoped>

</style>