<template>
    <v-app>
        <!-- S: HEADER -->
        <main-header></main-header>
        <!-- E: HEADER -->

        <!-- S: CONTENT -->
        <v-main id="main">
            <div id="contents">
                <slot />
            </div>
        </v-main>
        <!-- E: CONTENT -->

        <!-- S: FOOTER -->
        <main-footer></main-footer>
        <!-- E: FOOTER -->

        <quick-menu></quick-menu>

        <u-scroll-top></u-scroll-top>
    </v-app>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
import QuickMenu from "@/components/client/inc/quick-menu.vue";
import UScrollTop from "@/components/dumb/u-scroll-top.vue";

export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
        MainHeader,
        MainFooter,
        QuickMenu,
        UScrollTop,
    },
    mounted() {
        window.addEventListener("copy", this.preventCopy);
        window.addEventListener("contextmenu", this.preventCopy);
        window.addEventListener("selectstart", this.preventDefault);
        window.addEventListener("dragstart", this.preventDefault);
        this.init();
    },
    destroyed() {
        window.removeEventListener("copy", this.preventCopy);
        window.removeEventListener("contextmenu", this.preventCopy);
        window.removeEventListener("selectstart", this.preventDefault);
        window.removeEventListener("dragstart", this.preventDefault);
    },
    methods: {
        onScroll(event) {
          console.log(this.$refs.scrollbar.ps, event);
        },
        // preventCopy(event) {
        //     alert("보안 정책에 의해 복사를 허용하지 않습니다.");
        //     event.preventDefault();
        // },
        // preventDefault(event) {
        //     event.preventDefault();
        // },
        init : function() {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 240,
                    duration: 500,
                    easing : 'ease-in-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
    },
};
</script>
