<template>
    <div class="sub-visual secondary">
        <div class="sub-visual-tit">
            <v-container>
                <h2 data-aos="fade">{{tit}}</h2>
                <slot/>
            </v-container>
        </div>
        <div class="sub-visual-bg" :style="'background-image:url('+ bg +');'" data-aos="fade"></div>
    </div>
</template>

<script>

export default{
    props : {
        tit : {type :String, default : ""},
        bg : {type :String, default : ""},
    },
    components: {
    },
    data: () => {
        return {
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
        init : function() {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 240,
                    duration: 500,
                    easing : 'ease-in-out',
                    anchorPlacement : 'top-center',
                });
            }, 100);
		}
	},
}
</script>

<style scoped>
.sub-visual{
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}
.sub-visual-tit{
    position: relative;
    z-index: 1;
}
.sub-visual-tit h2{
    font-size: 2.8rem;
    line-height: 1;
    font-weight: 700;
    color: #fff;
}
.sub-visual-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width:576px){
}
@media (min-width:768px){
    .sub-visual{
        height: 260px;
    }
    .sub-visual-tit h2{
        font-size: 4rem;
    }
}
@media (min-width:1024px){
    .sub-visual{
        padding-top: var(--header-body);
        height: 388px;
    }
    .sub-visual-tit h2{
        font-size: 4.8rem;
    }
}
@media (min-width:1200px){
}
</style>